import React from "react"
import Cover from "../../components/cover"
import StandardLayout from "../../layouts/standard"
import blogsCover from "../../images/insight/Group 3.jpg"
import { Link } from "gatsby"

export const BlogsCard = props => {
  return (
    <div>
      <h1> {props.title} </h1>
      <img src={require(props.src).default} />
    </div>
  )
}

export default function Blogs(props) {
  const { blogCategories } = require("../../data.json")

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str
  }

  return (
    <StandardLayout title="Blogs">
      <img src={blogsCover} className="blog-cover"  />
      <section className="uk-section">
        <div className="uk-container">
          <div className="insight-head">
            <div>Insights</div>
            <ul className="uk-subnav uk-subnav-pill mv4 uk-flex uk-flex-center raleway">
              <li className="uk-active">
                <a href="#" className="active-a" style={{ textTransform: "none" }}>
                  Blog
                </a>
              </li>
              <li>
                <a href="/insights/news/1" style={{ textTransform: "none" }}>
                  News
                </a>
              </li>
              <li>
                <a href="/insights/magazines" style={{ textTransform: "none" }}>
                  Magzines
                </a>
              </li>
              <li>
                <a href="/insights/events" style={{ textTransform: "none" }}>
                  Events
                </a>
              </li>

              {/* {blogCategories.map(cat => (
                <li data-uk-filter-control={"." + cat.url}>
                  <a href="#">{cat.name}</a>
                </li>
              ))} */}
            </ul>
          </div>

          <div className="mt5 pb6" data-uk-filter="target: #blog-cards">
            <ul
              id="blog-cards"
              className="pa0 ma0 uk-child-width-1-3@s uk-child-width-1"
              data-uk-grid="masonry: true"
              style={{ listStyle: "none" }}
            >
              {blogCategories.map(cat => {
                return (
                  <>
                    {cat.posts.map(post => (
                      <>
                        <li className={"all " + cat.url}>
                          <Link
                            to={`/blogs/${cat.url}/${post.url}`}
                            className="standard-link"
                          >
                            <div className="uk-card uk-card-default">
                              <div className="w-100 uk-background-cover h-100">
                                <img
                                  className="card-img"
                                  src={
                                    post.thumbnail && post.thumbnail.publicURL
                                  }
                                />
                              </div>
                              <div className="pa3 bg-card">
                                <div>
                                  <h3 className="ma0 pb0 tt-n blog-h3 raleway">
                                    {post.title}
                                  </h3>
                                </div>
                                <div className="mt3 pb0">
                                  <p className="raleway turncate blog-desc">
                                    {truncate(post.description, 120)}
                                  </p>
                                  <button className="know-more">
                                    Know More
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </li>
                      </>
                    ))}
                  </>
                )
              })}
            </ul>
          </div>
        </div>
      </section>
    </StandardLayout>
  )
}
