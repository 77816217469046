const imageExtensions = [
    '.jpeg',
    '.jpg',
    '.png',
    '.svg',
    '.gif'
];

const videoExtensions = [
    '.mkv',
    '.mov',
    '.mp4',
    '.avi'
]

const purify = e => JSON.parse(JSON.stringify(e));

module.exports = {
    imageExtensions,
    videoExtensions,
    purify
}